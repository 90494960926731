import bobby from '../bobby.jpg'
import moment from 'moment'


function Post(props) {
  const publishByText = "Posted By Bobby " + moment(props.date).fromNow()
  return <div id={props.id} class="flex-col items-center max-w-sm bg-white rounded overflow-hidden shadow-lg m-5">

    <div class="flex justify-center items-center">
      { props.image ? <img class="px-2 w-36 mt-3 mb-3" alt="product description" src={props.image}></img> : <div></div>}
      <div class="text-center px-6 py-4">
        <a class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={props.link ? props.link : 'https://api.bobbysdeals.net/link?ref=web&code=' + props.id}>{props.title}</a>
      </div>
    </div>
    <p class="text-center px-6 py-4 text-gray-700 text-base">
      {props.description}
    </p>
    <div class="flex flex-row justify-start items-end px-6 pt-4 pb-2">
      <img class="w-10 h-10 rounded-full" src={bobby} alt="Bobby Wartheimer"></img>
      <div className='ml-1'>{publishByText}</div>
    </div>
  </div>;
}

export default Post;
