import Header from "./components/Header";
import About from "./about.jpg";
import PiggyTwo from "./piggy2.jpg";

function about() {
  return (
    <div class="flex flex-col h-screen ">
      <Header class="flex-1" />
      <div class="relative w-full max-h-[38rem]">
        <img src={About} alt="Avatar" class="object-cover w-full h-full" />
        <div class="absolute w-full py-2.5 bottom-3 md:bottom-10 text-white text-center leading-4 md:text-6xl text-3xl">
          The Story Behind Bobby
        </div>
      </div>

      <p class="text-center m-5 text-2xl md:mx-32">
        Bobby's Deals was founded in 2013 as an exclusive and elite Whatsapp
        group by Bobby, Rose, and Stuart Wertheimer. Following breakthrough
        success and growth, the platform grew by leaps and bounds throughout the
        last decade to become the de facto private deal community. In February
        of 2022, the founding team decided that it was time for the public to be
        able to take advantage of these amazing curated bargains and opened the
        public facing bobbysdeals.net website. We work tirelessly throughout the
        day with our community to cull the most amazing curated deals from the
        internet for everyone to take advantage of.
      </p>
      <img
        src={PiggyTwo}
        alt="Avatar"
        class="object-cover w-full max-h-[24rem]"
      />
      <div class="text-center text-blue-600 text-3xl my-3">Our Promise</div>
      <p class="text-center m-5 text-xl md:mx-32">
        Bobby's Deals is unlike all the other deal sites out there. We guarantee
        to cut through all the bribes and affiliate nonsense to bring you plain
        high quality deals. Many other deal sites will only show deals that they
        make affiliate commision on, but at Bobby's Deals we prioritize quality,
        whether or not we recieve a commission. This results in a impeccable
        deal experience that keeps our users coming back time and time again.
      </p>
      <div class="text-center text-blue-600 text-3xl my-3">Contact</div>
      <p class="text-center m-5 text-xl md:mx-32">
        Questions? Comments? Email us at bobby@bobbysdeals.net
      </p>
      <div class="text-center text-blue-600 text-3xl my-3">
        Advertiser Disclosure
      </div>
      <p class="text-center text-xl m-5 md:mx-32 pb-10">
        While we only post deals that meet our quality guidelines and do not
        prioritize affiliate listings, Many of the listings that appear on this
        website are from companies through which we receive compensation. The
        site does not include all companies or all available products. All
        prices are valid at the time of posting and are subject to change.
        BobbysDeals.net is a member of the Amazon Services LLC Associates
        Program, an affiliate advertising program designed to provide a means
        for sites to earn advertising fees by advertising and linking to
        amazon.com. As an Amazon Associate we earn from qualifying purchases. If
        you buy something from a product post on our website, Bobbysdeals.net
        may receive a commission of the sale.
      </p>
    </div>
  );
}

export default about;
