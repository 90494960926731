import React, { useState, useEffect, useRef } from "react";
import Post from "./components/Post";
import Header from "./components/Header"

let pageNumber = 0;
let isLoading = false;
let loadedMax = false;

function App() {
  const [data, setData] = useState([]);

  const scrollingList = useRef();

  useEffect(() => {
    const req = new XMLHttpRequest();
    const url = "https://api.bobbysdeals.net/posts/get";
    req.open("GET", url);
    req.send();

    req.onreadystatechange = (e) => {
      if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
        console.log(req.response);
        setData(JSON.parse(req.response));
      } else {
        console.log(
          "request has not been completed or did not return a 200 status code"
        );
      }
    };
  }, []);

  const onScroll = () => {
    if (loadedMax) {
      return;
    }
    if (scrollingList.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollingList.current;
      if (scrollTop + clientHeight > scrollHeight * 0.75) {
        loadMoreData();
      }
    }
  };

  function loadMoreData() {
    console.log("Loading more data called");
    if (isLoading) {
      console.log(
        "Is currently loading data so don't load more until completion"
      );
      return;
    }
    isLoading = true;
    pageNumber++;
    const req = new XMLHttpRequest();
    const url =
      "https://api.bobbysdeals.net/posts/get?pageNumber=" + pageNumber;
    req.open("GET", url);
    req.send();

    req.onreadystatechange = (e) => {
      if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
        console.log(req.response);
        const newPosts = JSON.parse(req.response);
        if (newPosts.length < 1) {
          loadedMax = true;
          return;
        }
        setData((data) => [...data, ...newPosts]);
        isLoading = false;
      } else {
        console.log(
          "request has not been completed or did not return a 200 status code"
        );
      }
    };
  }

  const listItems = data.map((post) => {
    return (
      <Post
        key={post.id}
        description={post.description}
        title={post.title}
        image={post.imageLink}
        id={post.id}
        date={post.dateCreated}
      />
    );
  });

  return (
    <div class="flex flex-col h-screen bg-slate-100 overflow-hidden">
      <Header/>
      <div ref={scrollingList} onScroll={onScroll} class="overflow-y-scroll">
        <div class="flex flex-1 flex-wrap justify-center items-center">
          {listItems}
        </div>
      </div>
    </div>
  );
}

export default App;
