import React, { useState } from 'react';
import { useAuth } from './backend/use-auth';
import { useNavigate } from 'react-router-dom';

function Signin() {
    const navigate = useNavigate();

    const auth = useAuth();

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    function signin() {
        if (username !== "" && password !== "") {
            auth.signin(username, password)
        }
    }


    return (

        <div >
            {auth.user ? (navigate('/admin')) :
                (<div class="flex items-center justify-center w-full">
                    <form class="flex-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-sm mt-6">
                        <p class="text-xl text-center mb-8">BobbysDeals Login</p>
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                                Username
                            </label>
                            <input value={username} onChange={e => setUserName(e.target.value)} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username"></input>
                        </div>
                        <div class="mb-6">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                Password
                            </label>
                            <input value={password} onChange={e => setPassword(e.target.value)} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************"></input>
                        </div>
                        <div class="flex items-center justify-between">
                            <button onClick={(e) => { signin() }} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                Sign In
                            </button>
                        </div>
                        <p class="text-center text-gray-500 text-xs mt-5">
                            &copy;2022 BobbyDeals LLC. All rights reserved.
                        </p>
                    </form>
                </div>)
            }
        </div>
    );
}

export default Signin;
