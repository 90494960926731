import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Admin from './Admin';
import SinglePost from './SinglePost';
import EditPost from './EditPost';
import About from './About';
import Clicks from './Clicks';
import FollowUs from './FollowUs';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import NewPost from './NewPost';
import { ProvideAuth } from './backend/use-auth';
import Signin from './Signin';
import Search from './components/Search';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ProvideAuth>
      {
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App/>} />
            <Route path="/Admin" element={<Admin/>} />
            <Route path="/newpost" element={<NewPost/>} />
            <Route path="/signin" element={<Signin/>} />
            <Route path="/post/:id" element={<SinglePost/>} />
            <Route path="/editpost/:id" element={<EditPost/>} />
            <Route path="/clicks/:id" element={<Clicks/>} />
            <Route path="/followus/" element={<FollowUs/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/search" element={<Search/>} />
          </Routes>
        </BrowserRouter>
      }
    </ProvideAuth>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
