import React, { useState, useEffect } from 'react';
import Post from './components/Post';
import { useParams } from 'react-router-dom';
import Header from './components/Header';

function SinglePost() {

    const { id } = useParams();

    const [post, setPost] = useState()

    useEffect(() => {
        const Http = new XMLHttpRequest();
        const url = 'https://api.bobbysdeals.net/post/get?id=' + id;
        Http.open("GET", url);
        Http.send();

        Http.onreadystatechange = (e) => {
            console.log(Http);
            console.log(JSON.parse(Http.response))
            setPost(JSON.parse(Http.response));
        }
    }, [id]);

    return (
        <div className='bg-slate-100 h-screen w-screen'>
              <Header/>
            {post ? <div> <div className='flex flex-row justify-center items-center pt-3'>
            </div>
                <div className='flex flex-wrap justify-center items-center'>
                    <Post description={post.description} title={post.title} image={post.imageLink} id={post.id} date={post.dateCreated} />
                </div> </div> : <div></div>}

        </div>
    );
}

export default SinglePost;
