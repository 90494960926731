import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-hooks-web';
import Post from './Post';
import Header from './Header';
import 'instantsearch.css/themes/satellite.css';

const searchClient = algoliasearch('SJTS9FE6X3', '286ea11a6bcbbffbacd77886f0f484a0');


function Hit({hit }) {
    console.log(hit);
    return (
        <Post
        key={hit.id}
        description={hit.description}
        title={hit.title}
        image={hit.imageLink}
        id={hit.id}
        date={hit.dateCreated}
      />
    );
  }

function Search(props) {
  return (
    <div class="flex flex-col h-screen bg-slate-100 overflow-hidden">
    <Header/>
    <div class="overflow-y-scroll">
    <InstantSearch searchClient={searchClient} indexName="bobbysdeals">
        <div class="flex flex-1 justify-center ">
    <SearchBox   classNames={{
          root: 'py-10 shadow-sm',
          form: 'relative',
          input: 'w-96'
        }} />
    </div>
       <div class="flex flex-1 flex-wrap justify-center items-center">
       <Hits hitComponent={Hit} />
        </div>
    </InstantSearch>
    </div>
  </div>
  );
}


export default Search;