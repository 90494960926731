import bobby from '../logo-header.png'
import { useState } from 'react';
import {GiHamburgerMenu} from 'react-icons/gi'

function Header() {

    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <>
          <nav class="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white shadow">
            <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <a
                  class="text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase text-white"
                  href="/"
                >
                  <img class="max-h-10 md:max-h-20" src={bobby} alt="header"></img>
                </a>
                <button
                  class="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <GiHamburgerMenu class="fill-gray-600 hover:opacity-75"/>
                </button>
              </div>
              <div
                class={
                  "lg:flex flex-grow items-center" +
                  (navbarOpen ? " flex" : " hidden")
                }
                id="example-navbar-danger"
              >
                <ul class="flex flex-col lg:flex-row list-none mt-3 lg:ml-auto">

                  <li class="nav-item">
                    <a
                      class="px-3 py-2 flex items-center text-xs md:text-lg uppercase font-bold leading-snug text-gray-600 hover:opacity-75"
                      href="/"
                    >
                      <i class="text-xl leading-lg text-white opacity-75"></i><span class="ml-2">Deals</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="px-3 py-2 flex items-center text-xs md:text-lg uppercase font-bold leading-snug text-gray-600 hover:opacity-75"
                      href="/search"
                    >
                      <i class="text-xl leading-lg text-white opacity-75"></i><span class="ml-2">Search</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="px-3 py-2 flex items-center text-xs md:text-lg  uppercase font-bold leading-snug text-gray-600 hover:opacity-75"
                      href="/followus"
                    >
                      <i class="text-xl leading-lg text-white opacity-75"></i><span class="ml-2">Follow Us</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="px-3 py-2 flex items-center text-xs md:text-lg  uppercase font-bold leading-snug text-gray-600 hover:opacity-75"
                      href="/about"
                    >
                      <i class="text-xl leading-lg text-white opacity-75"></i><span class="ml-2">About</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </>
      );
}



export default Header;