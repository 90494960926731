import header from './header.png'
import React from 'react';
import { useAuth } from './backend/use-auth';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
function Admin() {

    const auth = useAuth();
    const navigate = useNavigate()

    const [data, setData] = useState([])

    useEffect(() => {
        if (!auth.user) return
        const Http = new XMLHttpRequest();
        const url = 'https://api.bobbysdeals.net/admin/posts/get';
        Http.open("GET", url);
        Http.setRequestHeader('Authorization', auth.user.accessToken);
        Http.send();

        Http.onreadystatechange = (e) => {
            console.log(Http);
            setData(JSON.parse(Http.response));
        }
    }, [auth]);

    const listItems = data.map((post) =>
        <tr>
            <td class="border text-center">{post.title}</td>
            <td class="border text-center"><a href={"/clicks/" + post.id}>{post.clicks}</a></td>
            <td class="border text-center"><button onClick={(e) => { navigate("/editpost/" + post.id) }} class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Edit</button></td>
            <td class="border text-center"><button onClick={(e) => { deletePost(post.id) }} class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Delete</button></td>
        </tr>
    );

    function signOut() {
        auth.signout();
    }

    function deletePost(id) {
        const Http = new XMLHttpRequest();
        const url = 'https://api.bobbysdeals.net/posts/delete/' + id;
        Http.open("DELETE", url);
        Http.setRequestHeader('Authorization', auth.user.accessToken);
        Http.send();

        Http.onreadystatechange = (e) => {
            console.log(Http.status);
            window.location.reload()
        }
    }


    console.log(auth.user)
    return (
        <div>
            {auth.user ? (<main class="bg-gray-100 dark:bg-gray-800 rounded-2xl h-screen overflow-hidden relative">
                <div class="flex items-start justify-between">
                    <div class="h-screen hidden lg:block my-4 ml-4 shadow-lg relative w-80">
                        <div class="bg-white h-full rounded-2xl dark:bg-gray-700">
                            <div class="flex items-center justify-center pt-6 hover:bg-slate-300">
                                <img alt="header" onClick={(e) => { navigate('/') }} src={header}></img>
                            </div>
                            <nav class="mt-6">
                                <div>
                                    <a class="w-full font-thin uppercase text-blue-500 flex items-center p-4 my-2 transition-colors duration-200 justify-start bg-gradient-to-r from-white to-blue-100 border-r-4 border-blue-500 dark:from-gray-700 dark:to-gray-800 border-r-4 border-blue-500" href="/admin">
                                        <span class="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                                                </path>
                                            </svg>
                                        </span>
                                        <span class="mx-4 text-sm font-normal">
                                            Dashboard
                                        </span>
                                    </a>
                                    <a class="w-full font-thin uppercase text-gray-500 dark:text-gray-200 flex items-center p-4 my-2 transition-colors duration-200 justify-start hover:text-blue-500" href="/newpost">
                                        <span class="text-left">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                        <span class="mx-4 text-sm font-normal">
                                            New Post
                                        </span>
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
                        <header class="w-full shadow-lg bg-white dark:bg-gray-700 items-center h-16 rounded-2xl z-40">
                            <div class="relative z-20 flex flex-col justify-center h-full px-3 mx-auto flex-center">
                                <div class="relative items-center justify-center pl-1 flex w-full lg:max-w-68 sm:pr-2 sm:ml-0">
                                    <button onClick={(e) => { navigate('/newpost') }} class="mr-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">New Post</button>
                                    <p class="text-center">Signed in as {auth.user.email}</p>
                                    <button onClick={(e) => { signOut() }} class="ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Sign Out</button>
                                </div>
                            </div>
                        </header>
                        <div class="overflow-auto h-screen pb-24 pt-2 pr-2 pl-2 md:pt-0 md:pr-0 md:pl-0">
                            <div class="flex justify-center flex-col flex-wrap sm:flex-row ">
                                <div class="">
                                    <div class="mb-4">
                                        <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-700 w-full">
                                            <div class="flex flex-col">
                                                <span class="font-bold text-md text-black dark:text-white ml-2">
                                                    Recent Posts
                                                </span>
                                            </div>
                                            <table class="w-full border table-auto">
                                                <thead>
                                                    <tr>
                                                        <th class="border">Deal Title</th>
                                                        <th class="border">Clicks</th>
                                                        <th class="border">Edit</th>
                                                        <th class="border">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listItems}
                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>) : (
                navigate('/signin')
            )}

        </div>
    );
}

export default Admin;
