import React, { useEffect, useState } from 'react';
import { useAuth } from './backend/use-auth';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import moment from 'moment';


function Clicks() {

    const auth = useAuth();
    const navigate = useNavigate();
    const { id } = useParams();

    const [data, setData] = useState([])
    const [title,setTitle] = useState()

    useEffect(() => {
        if (!auth.user || !id) return
        const Http = new XMLHttpRequest();
        const url = 'https://api.bobbysdeals.net/admin/links/get?id=' + id;
        Http.open("GET", url);
        Http.setRequestHeader('Authorization', auth.user.accessToken);
        Http.send();

        Http.onreadystatechange = (e) => {
            console.log(Http.response);
            setData(JSON.parse(Http.response).content);
        }
        const Https = new XMLHttpRequest();
        const urls = 'https://api.bobbysdeals.net/post/get?id=' + id;
        Https.open("GET", urls);
        Https.send();

        Https.onreadystatechange = (e) => {
            console.log(Https);
            const post =JSON.parse(Https.response);
            setTitle(post.title)
        }
    }, [auth, id]);




    const listItems = data.map((click) =>
        <tr>
            <td class="border text-center p-2">{click.ipAddress}</td>
            <td class="border text-center p-2">{click.location}</td>
            <td class="border text-center p-2">{click.isp}</td>
            <td class="border text-center p-2">{click.ref}</td>
            <td class="border text-center p-2">{moment(click.dateCreated).fromNow()}</td>
        </tr>
    );


    return (
        <div className='bg-slate-100'>
            {auth.user ? (
                <div class="flex justify-center flex-col flex-wrap sm:flex-row ">
                    <div class="">
                        <div class="mb-4">
                            <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-700 w-full">
                                <div class="flex flex-col">
                                    <span class="font-bold text-md text-center text-black dark:text-white ml-2 mr-2 mb-4">
                                        Clicks for {title}
                                    </span>
                                </div>
                                <table class="w-full border table-auto">
                                    <thead>
                                        <tr>
                                            <th class="border">IP Address</th>
                                            <th class="border">Location</th>
                                            <th class="border">Isp</th>
                                            <th class="border">Referral</th>
                                            <th class="border">Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItems}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>) : (navigate("/signin"))}

        </div>
    );
}

export default Clicks;


