import React, { useState } from 'react';
import Post from './components/Post';
import { useAuth } from './backend/use-auth';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from './components/Dialog';
import 'react-toastify/dist/ReactToastify.css';

let btnClicked = false;

function NewPost() {

    const auth = useAuth();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const [showImage, setShowImage] = useState(true)
    const [imageUrl, setImageUrl] = useState("");
    const [showWarning,setShowWarning] = useState(false);

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    function setUrlAndImageUrl(url) {
        setLink(url);
        if (!isValidHttpUrl(url)) {
            return null;
        }
        const req = new XMLHttpRequest();
        const reqUrl = 'https://api.linkpreview.net/?key=87d5862c0cf71db707c65cc6e7164679&q=' + url;
        req.open("GET", reqUrl);
        req.send();

        req.onreadystatechange = (e) => {
            console.log(req.status + " - " + req.responseText);
            if (req.status === 200) {
                const resp = JSON.parse(req.responseText)
                setImageUrl(resp.image)
            }
        }
    }

    function postButtonClicked(){
        console.log("button clicked")
        if (description === "" && title === "") {
            console.log("No title or description entered, aborting");
            toast("Error! A post must contain either a title or a description, please ensure you have at least one and try again!");
            return;
        }
        if ((description.length + title.length) > 254) {
            toast("Error! A post cannot contain more then 254 characters in total, please ensure your post has less then 254 characters and try again!");
            return
        }
        if(link !== ""){
            createPost()
        } else {
            setShowWarning(true)
        }
        
    }


    function createPost() {
        if(btnClicked) return
        btnClicked = true
        toast("Creating post...");
        const req = new XMLHttpRequest();
        const url = 'https://api.bobbysdeals.net/posts/add';
        req.open("POST", url);
        req.setRequestHeader('Content-Type', 'application/json');
        req.setRequestHeader('Authorization', auth.user.accessToken);
        const post = {
            "title": title,
            "description": description,
            "link": link,
            "imageLink": imageUrl,
        }
        req.send(JSON.stringify(post));

        req.onreadystatechange = (e) => {
            console.log(req.status + " - " + req.responseText);
            if (req.status === 200) {
                navigate("/admin")
                
            }
            btnClicked = false
        }
    }


    return (
        <div className='bg-slate-100'>
            {auth.user ? (
                <div className='bg-slate-100'>
                    {showWarning ? <Dialog title="Confirm Post" description="Your post currently does not contain a product link, are you sure you want to post it?" okText="Post" cancelText="Cancel" okMethod={() => {createPost()}} cancelMethod={() => {setShowWarning(false)}} /> : <></> }
                    <ToastContainer />
                    <p class="m-5 text-5xl text-center">Post Creator</p>
                    <div className='flex flex-wrap justify-center items-center pt-3'>
                        <div class="w-full max-w-md">
                            <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div class="mb-4">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                                        Title
                                    </label>
                                    <textarea value={title} onChange={e => setTitle(e.target.value)} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="title" type="text" placeholder="title"></textarea>
                                </div>
                                <div class="mb-6">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                        Description
                                    </label>
                                    <textarea value={description} onChange={e => setDescription(e.target.value)} class="h-36 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="enter post description here"></textarea>
                                </div>
                                {showImage ? <div class="mb-6">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                        Image Link
                                    </label>
                                    <input value={imageUrl} onChange={e => setImageUrl(e.target.value)} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="enter image link here"></input>
                                </div> : <div></div>}

                                <div class="mb-6">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                        Link
                                    </label>
                                    <input value={link} onChange={e => setUrlAndImageUrl(e.target.value)} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="enter post description here"></input>
                                </div>
                                <div class="flex justify-center mb-6">
                                    <div class="form-check form-switch">
                                        <input checked={showImage} onChange={e => setShowImage(!showImage)} class="form-check-input w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="flexSwitchCheckChecked"></input>
                                        <label class="form-check-label inline-block text-gray-800" for="flexSwitchCheckChecked">Display Image</label>
                                    </div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <button onClick={e => { postButtonClicked() }} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                        Create
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <p class="text-3xl">Preview</p>
                            <Post description={description} image={showImage ? imageUrl : null} title={title} link={link} date={new Date().getTime()} />
                        </div>
                    </div>
                </div>) : (navigate("/signin"))}

        </div>
    );
}

export default NewPost;
