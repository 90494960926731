import React, { useState, useEffect, useContext, createContext } from "react";
import { getAuth, signInWithEmailAndPassword,signOut, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyDyjzSK6TvbpchjyDQWLF7Pv6S8tz9FTPU",
    authDomain: "bobbysdeals-b3921.firebaseapp.com",
    projectId: "bobbysdeals-b3921",
    storageBucket: "bobbysdeals-b3921.appspot.com",
    messagingSenderId: "1018146094794",
    appId: "1:1018146094794:web:d47fc0ed2d753a88527e33",
    measurementId: "G-X9L8HL3C44"
  };
  
  // Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp)

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return signInWithEmailAndPassword(firebaseAuth,email, password)
      .then((response) => {
          if(response.user.emailVerified !== true){
            sendEmailVerification(response.user);
          }
        setUser(response.user);
        return response.user;
      });
  };
  const signout = () => {
    return signOut(firebaseAuth)
      .then(() => {
        setUser(false);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth,(user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
}