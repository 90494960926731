import twitter from "./twitter.png";
import Header from "./components/Header";
import truth from "./truth.svg";
import instagram from "./instagram.png";
import facebook from "./facebook.png";
import linkedin from "./linkedin.png";
import telegram from "./telegram.png";

function FollowUs() {
  return (
    <div class="flex flex-col h-screen bg-slate-100">
      <Header />
      <div class="w-screen flex flex-row justify-center bg-blue-500">
        <div class="m-5 text-xl text-white flex text-center">
          Follow Us On Social Media And Never Miss A Deal
        </div>
      </div>{" "}
      <div lass="overflow-y-scroll bg-slate-100">
        <div class="flex flex-1 flex-wrap justify-center items-center overflow-y-scroll bg-slate-100">
          <div class="flex-col h-72 w-80  items-center justify-center bg-white rounded shadow-lg m-5">
            <div class="w-36 mx-auto">
              <img
                class="px-2 w-full h-full mt-3 mb-3"
                alt="product description"
                src={twitter}
              ></img>
            </div>
            <div class="text-center px-6 py-4">
              <span>Follow Us On Twitter</span>
            </div>
            <p class="text-center px-6 py-4 text-gray-700 text-base">
              <a
                class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://twitter.com/BobbysDeals"
              >
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Follow
                </button>
              </a>
            </p>
          </div>
          <div class="flex-col h-72 w-80 items-center justify-center bg-white rounded shadow-lg m-5">
            <div class="w-36 mx-auto">
              <img
                class="px-2 w-full h-full mt-3 mb-3"
                alt="product description"
                src={telegram}
              ></img>
            </div>
            <div class="text-center px-6 py-4">
              <span>Follow Us On Telegram</span>
            </div>
            <p class="text-center px-6 py-4 text-gray-700 text-base">
              <a
                class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://t.me/+SfemR0UFL-ZjNDNh"
              >
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Follow
                </button>
              </a>
            </p>
          </div>
          <div class="flex-col h-72 w-80 items-center justify-center bg-white rounded shadow-lg m-5">
            <div class="w-36 mx-auto">
              <img
                class="px-2 w-full h-full mt-3 mb-3"
                alt="product description"
                src={facebook}
              ></img>
            </div>
            <div class="text-center px-6 py-4">
              <span>Follow Us On Facebook</span>
            </div>
            <p class="text-center px-6 py-4 text-gray-700 text-base">
              <a
                class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.facebook.com/BobbysDeals-107036752046105/"
              >
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Follow
                </button>
              </a>
            </p>
          </div>
          <div class="flex-col h-72 w-80 items-center justify-center bg-white rounded shadow-lg m-5">
            <div class="w-36 h-36 mx-auto">
              <img
                class="px-2 w-full h-full mt-3 mb-3 object-contain"
                alt="product description"
                src={truth}
              ></img>
            </div>
            <div class="text-center px-6 py-4">
              <span>Follow Us On Truth Social</span>
            </div>
            <p class="text-center px-6 py-4 text-gray-700 text-base">
              <a
                class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://truthsocial.com/@slickdeals"
              >
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Follow
                </button>
              </a>
            </p>
          </div>
          <div class="flex-col h-72 w-80  items-center justify-center bg-white rounded shadow-lg m-5">
            <div class="w-36 mx-auto">
              <img
                class="px-2 w-full h-full mt-3 mb-3"
                alt="product description"
                src={instagram}
              ></img>
            </div>
            <div class="text-center px-6 py-4">
              <span>Follow Us On Instagram</span>
            </div>
            <p class="text-center px-6 py-4 text-gray-700 text-base">
              <a
                class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.instagram.com/bobbysdeals/"
              >
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Follow
                </button>
              </a>
            </p>
          </div>
          <div class="flex-col h-72 w-80  items-center justify-center bg-white rounded shadow-lg m-5">
            <div class="w-36 h-36 mx-auto">
              <img
                class="px-2 w-full h-full mt-3 mb-3 object-contain"
                alt="product description"
                src={linkedin}
              ></img>
            </div>
            <div class="text-center px-6 py-4">
              <span>Follow Us On LinkedIn</span>
            </div>
            <p class="text-center px-6 py-4 text-gray-700 text-base">
              <a
                class="font-bold text-xl mb-2 no-underline hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.linkedin.com/in/bobbys-deals-181762243/recent-activity/"
              >
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Follow
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FollowUs;
